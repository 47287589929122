






































































import { Vue, Component, Prop } from "vue-property-decorator";
import { OrganisationSettingsModel } from "@/models/OrganisationSettingsModel";

@Component
export default class OrganisationSettings extends Vue {
  @Prop() settings!: OrganisationSettingsModel;
}
