














































import { Vue, Component } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { organisationModule } from "@/store/modules/OrganisationModule";

@Component
export default class OrganisationOverview extends Vue {
  organisationDetails = () => {
    return organisationModule.organisationDetails;
  }

  async aggregate() {
    await adminService.aggregate(this.organisationDetails().id);
  }

  async analyze() {
    await adminService.analyze(this.organisationDetails().id);
  }
}
