

















































import { Vue, Component } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { organisationModule } from "@/store/modules/OrganisationModule";
import OrganisationBase from "@/components/OrganisationBase.vue";
import OrganisationOverview from "@/components/OrganisationOverview.vue";
import OrganisationSettings from "@/components/OrganisationSettings.vue";
import { Organisation } from "@/models/Organisation";
import { GeoArea } from "@/models/GeoArea";
import { OrganisationSettingsModel } from "@/models/OrganisationSettingsModel";
import moment from "moment";

@Component({
  components: {
    OrganisationOverview,
    OrganisationBase,
    OrganisationSettings,
  },
})
export default class OrganisationManage extends Vue {
  organisation: Organisation = {
    id: undefined,
    name: "",
    key: "",
    areaId: undefined,
  } as Organisation;
  geoAreas: GeoArea[] = [];
  settings: OrganisationSettingsModel = {
    id: undefined,
    organisationId: undefined,
    isDownloadEnabled: false,
    ftpHostname: "",
    ftpPath: "",
    ftpUsername: "",
    ftpPassword: "",
    startYear: moment().year(),
  } as OrganisationSettingsModel;

  isInitialised = () => {
    return this.organisation.id !== undefined && this.geoAreas.length && this.settings.id !== undefined;
  }

  organisationDetails = () => {
    return organisationModule.organisationDetails;
  }

  async save() {
    Promise.all([
      adminService.saveOrganisation(this.organisation.id, this.organisation),
      adminService.saveOrganisationSettings(this.organisation.id, this.settings),
    ]).then((value) => {
      alert("Sparat!")
    });
  }

  private async activated() {
    this.organisation = {
      id: undefined,
      name: "",
      key: "",
      areaId: undefined,
    } as Organisation;
    this.settings = {
      id: undefined,
      organisationId: undefined,
      isDownloadEnabled: false,
      ftpHostname: "",
      ftpPath: "",
      ftpUsername: "",
      ftpPassword: "",
      startYear: moment().year(),
    } as OrganisationSettingsModel;
    
    if (organisationModule.organisation) {
      this.organisation = organisationModule.organisation;

      this.settings.organisationId = this.organisation.id;

      adminService.getOrganisationSettings(this.organisation.id).then((settingsResp) => {
        this.settings = settingsResp;
      }, (reason) => {
        console.log(reason);
      });
    }

    this.geoAreas = await adminService.getGeoAreas();
  }
}
