














































import { Vue, Component, Prop } from "vue-property-decorator";
import { Organisation } from "@/models/Organisation";
import { GeoArea } from "@/models/GeoArea";

@Component
export default class OrganisationBase extends Vue {
  @Prop() organisation!: Organisation;
  @Prop() geoAreas!: GeoArea[];
  @Prop() isAdding!: boolean;
}
